import { useTranslation } from "next-i18next";
import React from "react";
import { NOrderItem } from "../../../../interfaces";
import { FinalPriceTemplate } from "../../../../templates/discounted-price";
import { getLinkedProductNames, getOptionsNames } from "../../../../utils";
import { Image } from "antd";
import { FALLBACK_IMAGE } from "@common/constant";

interface IProps {
    data: NOrderItem.ICheckoutItem;
}

export const CartItem: React.FC<IProps> = ({ data }) => {
    const { t } = useTranslation("common");
    const { productSizeName, linkedProductGroups, optionTypes } = data;

    const linkedProductNames = getLinkedProductNames(linkedProductGroups);
    const optionsNames = getOptionsNames(optionTypes);

    const options = [productSizeName];
    linkedProductNames && options.push(linkedProductNames);
    optionsNames && options.push(optionsNames);

    const optionString = options.join(", ");

    return (
        <section className="flex justify-between gap-3">
            <Image
                width={102}
                height={102}
                src={data.photo ?? FALLBACK_IMAGE}
                alt={data.productName ?? ""}
                className="object-cover"
            />
            <article className="flex-1 flex flex-col gap-1   justify-between">
                <div className="flex justify-between w-full">
                    <p className="text-base text-neutral-60 font-semibold">
                        {data.productName}
                    </p>
                    <FinalPriceTemplate
                        productPrice={data.price}
                        discountedPrice={data.discount?.price}
                        direction={"horizontal"}
                    />
                </div>
                <p className="text-xs font-normal text-neutral-30 line-clamp-2">
                    {optionString}
                </p>
            </article>
        </section>
    );
};
