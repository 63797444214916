import { cn } from "@utils/tailwind-marge";
import { Button, Image } from "antd";
import { useAppDispatch, useAppSelector } from "@redux/store";
import { marketSelector, toggleOpenCart } from "@redux/slices";

export const StoreCartButton = () => {
    const dispatch = useAppDispatch();
    const { storeCarts } = useAppSelector(marketSelector);
    const toggleCart = () => {
        dispatch(toggleOpenCart(true));
    };

    const totalCarts = storeCarts?.length ?? 0;
    const bigNumber = totalCarts > 9;

    return (
        <Button
            className={cn(
                "fixed !right-4 !left-auto !bottom-4 !rounded-full w-14 h-14 !m-0 z-50",
                !totalCarts ? "hidden" : "",
            )}
            type="primary"
            onClick={toggleCart}
        >
            <Image
                src="/icons/shopping-basket.svg"
                alt="cart"
                width={16}
                height={16}
                preview={false}
            />
            <div
                className={cn(
                    "flex justify-center items-center",
                    "w-[13px] h-[13px]",
                    "bg-alert-red rounded-full",
                    "absolute top-[12px] right-[12px]",
                    bigNumber && "w-5 h-5",
                )}
            >
                <p className="text-[10px] text-white font-semibold">
                    {bigNumber ? "9+" : totalCarts}
                </p>
            </div>
        </Button>
    );
};
