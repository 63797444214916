import s from "./shop-item.module.scss";

import { FC } from "react";

import { cn } from "@utils/tailwind-marge";
import { NShop } from "../../../../interfaces";
import { StoreDistance } from "../components/distance";
import { Promotion } from "../components/promotion";
import { ShopInfoContainer } from "../components/shop-info-container";
import { ShopName } from "../components/shop-name";
import { Tag } from "../components/tag";
import { Image } from "antd";
import { FALLBACK_IMAGE, STORE_FALLBACK_IMAGE_PATH } from "@common/constant";
import { getPublicMediaUrl } from "@utils/resource";

type ShopItemProps = {
    shop: NShop.Item;
    quantity?: number;
    isHigher?: boolean;
    isMobile?: boolean;
};

export const ShopItemTemplate: FC<ShopItemProps> = ({ shop }) => {
    const { name, image } = shop;

    return (
        <section className={s.shopItem}>
            <div className={`${s.imageContainer} mb-1`}>
                <div
                    className="cursor-pointer overflow-hidden rounded-br-none rounded-bl-none rounded-tl-xl rounded-tr-xl"
                    // onClick={handleButtonShowDetail}
                >
                    <Image
                        width={"100%"}
                        src={image ? image : "error"}
                        alt="gift-card"
                        className={cn(
                            "aspect-[209/112] object-cover",

                            "hover:scale-110 transition",
                        )}
                        fallback={FALLBACK_IMAGE}
                        preview={false}
                    />
                    {/* 
                    <Tag
                        type="green"
                        className="rounded-tr-md rounded-br-md absolute top-3"
                    >
                        Khuyến mãi
                    </Tag> */}
                </div>
            </div>
            <ShopInfoContainer>
                {/* <Promotion
                    promotion={{
                        title: "Ưu đãi 10%",
                        description:
                            "Bạn có thêm 2 voucher khuyến mãi voucher khuyến mãi",
                    }}
                /> */}
                <ShopName>{name}</ShopName>
                {shop.distance?.value && (
                    <StoreDistance distance={shop.distance} />
                )}

                {/* <Rating rating={{ commentTotal: 0, rate: shop.rating }} /> */}
            </ShopInfoContainer>
        </section>
    );
};
