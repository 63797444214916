import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import React from "react";
import Slider, { LazyLoadTypes } from "react-slick";
import s from "./promotion-slider.module.scss";
import { useBannerSetting } from "@services/api-hook";
import { Image, Skeleton } from "antd";
import {
    BannerSetting,
    BannerTypeEnum,
} from "@data-access/models/banner-setting.model";
import { useParams, useRouter } from "next/navigation";
import { getPublicMediaUrl } from "@utils/resource";

export const PromotionSlider: React.FC = () => {
    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        variableWidth: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: "progressive" as LazyLoadTypes,
    };

    const { fetchBannerSetting } = useBannerSetting();
    const { data, isLoading } = fetchBannerSetting;

    const router = useRouter();
    const params = useParams();

    const handleClick = (banner: BannerSetting) => {
        switch (banner.type) {
            case BannerTypeEnum.Category:
                router.push(
                    `/${params?.lng}/search?category=${banner?.meta?.target}`,
                );
                break;

            case BannerTypeEnum.Link:
                window.location.assign(banner.meta.target);
                break;

            case BannerTypeEnum.Product:
                // lack of store info
                break;
        }
    };

    if (isLoading || !data?.banners) {
        return (
            <Skeleton.Button
                shape="square"
                active
                className="!w-screen !h-[300px]"
            />
        );
    }

    return (
        <section className={`${s.promotionSlider}`}>
            <Slider {...settings}>
                {data?.banners?.map((item) => (
                    <div key={item.id} onClick={() => handleClick(item)}>
                        <Image
                            src={getPublicMediaUrl(item.imageUrl)}
                            alt="banner"
                            className="!w-screen h-auto max-h-[300px] object-cover"
                            fallback={getPublicMediaUrl(data.imageFallbackUrl)}
                            preview={false}
                        />
                    </div>
                ))}
            </Slider>
        </section>
    );
};
