import { useTranslation } from "react-i18next";
import { DrawerCustom } from "../../modules/custom-drawer";
import { Image } from "antd";
import { StoreCarts } from "./store-carts";
import { IStoreCart } from "@interfaces/market/store-cart.interface";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { toggleOpenCart } from "../../../redux/slices";
import { Button } from "antd";
import { cn } from "../../../utils/tailwind-marge";
import { useRouter, useParams } from "next/navigation";
import { IStore } from "../../../interfaces";
import { useState } from "react";

export const StoreCart = () => {
    const router = useRouter();
    const params = useParams();

    const [selectedStore, setSelectedStore] = useState<IStoreCart>();

    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();

    const onClose = () => {
        dispatch(toggleOpenCart(false));
    };

    const onCheckoutStoreCart = () => {
        if (!selectedStore) {
            return;
        }
        router.push(`${params?.lng}/${selectedStore?.store}?openCart=true`);
    };

    const onSelectCart = (cart: IStoreCart) => {
        setSelectedStore(cart);
    };

    return (
        <>
            <DrawerCustom
                key={"checkout-order"}
                open={true}
                onClose={onClose}
                title={
                    <div>
                        <p className="text-lg font-semibold">
                            {t("common.your_cart_from")}
                        </p>
                    </div>
                }
                className="rounded-t-[24px]"
                height={"50%"}
                footer={
                    <Button
                        type="primary"
                        className={cn(
                            "h-10 rounded-full bg-primary flex justify-center items-center w-full",
                            "font-sans font-semibold text-base text-white",
                        )}
                        disabled={!selectedStore?.cart?.items?.length}
                        onClick={onCheckoutStoreCart}
                    >
                        {t("common.confirm")}
                    </Button>
                }
                zIndex={999}
                closeIcon={
                    <Image
                        width={32}
                        height={32}
                        alt="close"
                        src="/icons/remove.svg"
                    />
                }
                placement="bottom"
            >
                <StoreCarts onSelect={onSelectCart} />
            </DrawerCustom>
        </>
    );
};
