import { marketSelector } from "@redux/slices";
import { useAppSelector } from "@redux/store";
import { useEffect, useState } from "react";
import { IStoreCart } from "@interfaces/market/store-cart.interface";
import { CartItem } from "./cart-item";
import { NCart, NOrderItem } from "../../../interfaces";
import { useTranslation } from "react-i18next";
import { Dropdown, MenuProps } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { getSessionValue } from "../../../utils";
import { STORE_CARTS } from "../../../common/constant";

type Prop = {
    onSelect: (selectedItem: IStoreCart) => void;
};

export const StoreCarts = (props: Prop) => {
    const { onSelect } = props;
    const { t } = useTranslation("common");
    const { currentStore } = useAppSelector(marketSelector);
    const { storeCarts } = useAppSelector(marketSelector);
    const [items, setItems] = useState<MenuProps["items"]>([]);
    const [storeCart, setStoreCart] = useState<IStoreCart>();

    useEffect(() => {
        const carts = storeCarts?.length
            ? storeCarts
            : getSessionValue<IStoreCart[]>(STORE_CARTS);
        if (carts?.length) {
            const store = carts.find(
                (s: IStoreCart) => s.store === currentStore?.subDomain,
            );
            setStoreCart(store ?? carts[0]);
            onSelect?.(store ?? carts[0]);
            const menuItems = carts.map((item) => {
                return {
                    key: item.store,
                    label: (
                        <p onClick={() => onSelectStore(item)}>
                            {item.name ?? item.store}
                        </p>
                    ),
                };
            });
            setItems(menuItems);
        }
    }, [storeCarts]);

    const onSelectStore = (item: IStoreCart) => {
        setStoreCart(item);
        onSelect?.(item);
    };

    return (
        <div className="flex flex-col gap-4 rounded-t-md h-full">
            <Dropdown menu={{ items }}>
                <a>
                    <span className="text-2xl font-bold pr-2">
                        {storeCart?.name}
                    </span>
                    <DownOutlined rev={undefined} />
                </a>
            </Dropdown>
            {storeCart && (
                <>
                    <div className="flex flex-col gap-8 py-4 grow">
                        {storeCart?.cart?.items?.map(
                            (item: NCart.ICartItem) => {
                                const data: NOrderItem.ICheckoutItem = {
                                    total: item.total,
                                    qty: item.qty,
                                    price: item.total,
                                    productName: item.productName,
                                    productSizeName: item.productSizeName || "",
                                    linkedProductGroups:
                                        item.selectToppingGroups,
                                    optionTypes: item.selectOptionTypes,
                                    note: item.note,
                                    discount: item.discount,
                                    photo: item.productPhoto,
                                    id: item.id,
                                };

                                return <CartItem key={item.id} data={data} />;
                            },
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
