import s from "./shop-slider.module.scss";

import { FC } from "react";
import { Button, Spin } from "antd";

import Slider from "react-slick";
import { NShop } from "../../../../interfaces";
import { ShopItemTemplate } from "../shop-item";

const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    variableWidth: true,
    speed: 300,
    swipeToSlide: true,
};

type ShopSliderProps = {
    title: string;
    loading: boolean;
    buttonName: string;
    shops: NShop.Item[];
    hideIcon?: boolean;
    onViewDetail: (store: NShop.Item) => void;
};

export const ShopSlider: FC<ShopSliderProps> = ({
    title,
    buttonName,
    loading,
    shops,
    hideIcon,
    onViewDetail,
}) => {
    const isMobile = true;

    const ShopSeeMore = () =>
        isMobile ? (
            <div
                className="flex items-center cursor-pointer"
                // onClick={handleClick}
            >
                <Button className="text-xs font-light border-none outline-none shadow-none">
                    {buttonName}
                </Button>
            </div>
        ) : (
            <div
                className="flex justify-center items-center cursor-pointer mt-6"
                // onClick={handleClick}
            >
                <h5 className={s.more}>{buttonName}</h5>
                <img
                    src="/icons/arrow-down-icon.svg"
                    alt="arrow-down"
                    className={`${s.moreDesktopIcon} ml-1`}
                />
            </div>
        );

    return (
        <>
            {shops?.length ? (
                <section className={s.giftCard}>
                    <Spin spinning={loading} className="m-auto w-full">
                        <div className="flex items-center justify-between mb-6">
                            <div className="flex items-center">
                                {hideIcon ? null : (
                                    <>
                                        <img
                                            src="/icons/gift-card-fire-icon.svg"
                                            alt="Gift card Icon"
                                            className="h-8"
                                        />
                                        <img
                                            src="/icons/gift-card-fire-icon.svg"
                                            alt="Gift card Icon"
                                            className="h-8 mr-2"
                                        />
                                    </>
                                )}
                                <h1 className="!text-primary">{title}</h1>
                            </div>
                            {isMobile ? <ShopSeeMore /> : null}
                        </div>

                        <Slider {...settings}>
                            {shops?.map((shop) => (
                                <div
                                    key={shop.id}
                                    className={`${s.item} ${
                                        isMobile ? "mr-4" : "mr-6"
                                    }`}
                                    onClick={() => onViewDetail(shop)}
                                >
                                    <ShopItemTemplate
                                        shop={shop}
                                        key={shop.id}
                                    />
                                </div>
                            ))}
                        </Slider>
                    </Spin>
                    {!isMobile && shops.length > 4 ? <ShopSeeMore /> : null}
                </section>
            ) : (
                <></>
            )}
        </>
    );
};
