export class BannerSettingDto {
    id?: string;

    banners: Array<BannerSetting>;

    imageFallbackUrl: string;
}

export enum BannerTypeEnum {
    Product = "product",
    Category = "category",
    Link = "link",
}

export class BannerSetting {
    id?: string;
    imageUrl: string;
    type: BannerTypeEnum;
    meta: {
        target: string;
    };
    endTime: Date;
    order?: number;
}
