import useSWR from "swr";
import { Skeleton } from "antd";
import { NShop, StoreSettingType } from "@interfaces";
import { StoreService } from "../../../services/api/store.service";
import { FIXED_CATEGORY } from "../../../common/constant";
import { ShopSlider } from "../../modules/shop-list/shop-slider";
import { getPublicMediaUrl } from "../../../utils";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/store";
import { guestSelector } from "../../../redux/slices";

type Prop = {
    onViewDetail: (store: NShop.Item) => void;
};

export const SuggestionStores = (props: Prop) => {
    const { onViewDetail } = props;
    const { t } = useTranslation("common");
    const service = StoreService.getInstance();
    const { lat, lng } = useAppSelector(guestSelector);
    const latLng =
        lat && lng
            ? {
                  lat,
                  lng,
              }
            : null;

    const params = {
        pageIndex: 1,
        pageSize: 10,
        latlng: latLng,
        category: FIXED_CATEGORY.suggestion,
    };

    const { data, isLoading } = useSWR(["suggested-stores", params], () => {
        return service.search(params);
    });

    const stores = data?.data?.map((item) => {
        const image =
            item.settings?.find(
                (setting) => setting.type === StoreSettingType.avatar,
            )?.value ?? "";
        const address = item.location?.orderingAddress ?? "";
        return {
            id: item.id,
            name: item.name,
            image: getPublicMediaUrl(image),
            address: address,
            distance: item.distance,
            rating: item.rating,
            subDomain: item.subDomain,
        } as NShop.Item;
    });

    return (
        <>
            {isLoading && <Skeleton active />}
            {!isLoading && (
                <ShopSlider
                    title={t("shop.hint")}
                    buttonName={t("common.view_all")}
                    loading={isLoading}
                    shops={stores ?? []}
                    onViewDetail={onViewDetail}
                />
            )}
        </>
    );
};
