import { Input } from "antd";
import React, { useEffect, useState } from "react";

import s from "./marketplace-template.module.scss";

import { MarketHeader } from "@components/market-header";

import { STORE_CARTS } from "@common/constant";
import { StoreCart } from "@components/market/store-cart";
import { StoreCartButton } from "@components/market/store-cart-button";
import { useCheckoutContext } from "@components/modules/checkout/checkout.context";
import { PromotionSlider } from "@components/modules/promotion-slider";
import { IStoreCart } from "@interfaces/market/store-cart.interface";
import {
    marketSelector,
    setCurrentStore,
    updateFromSession,
} from "@redux/slices";
import { useAppDispatch, useAppSelector } from "@redux/store";
import { CheckoutResultParams } from "@templates/header/desktop";
import { getSessionValue, logSearch } from "@utils";
import { useParams, useRouter } from "next/navigation";
import { useTranslation } from "react-i18next";
import { BusinessTypes } from "../../components/market/business-types";
import { NearlyStores } from "../../components/market/nearly-categories";
import { StoreCategories } from "../../components/market/store-categories";
import { SuggestionStores } from "../../components/market/suggestion-stores";
import { NShop } from "../../interfaces";
import { SearchLogService } from "../../services/api/search-log.service";
import { useCurrentLocation } from "../../hooks";

export const MarketplaceTemplate: React.FC = () => {
    const { getLocationApp } = useCurrentLocation();

    const { t } = useTranslation("common");
    const router = useRouter();
    const params = useParams();
    const searchLogService = SearchLogService.getInstance();

    const dispatch = useAppDispatch();
    const checkoutContext = useCheckoutContext();

    const [filter, setFilter] = useState<string>("");

    const [showModalCheckoutResult, setShowModalCheckoutResult] =
        useState<CheckoutResultParams>({ isShow: false });

    const onViewDetail = (store: NShop.Item) => {
        if (store?.id && store?.subDomain) {
            dispatch(setCurrentStore(store));
            router.push(`/${params?.lng}/${store?.subDomain}`);
        } else {
            router.push("/");
        }
    };
    const { isOpenStoreCart } = useAppSelector(marketSelector);
    let storeCarts = getSessionValue<IStoreCart[]>(STORE_CARTS);

    // const onCloseWhenConfirmOrder = (data?: NCheckout.ConfirmDineIn) => {
    //     dispatch(toggleShowCart(false));
    //     const checkoutOrderData = data?.order ? { ...data.order } : undefined;
    //     checkoutContext.setConfirmDineIn({
    //         data: false,
    //         order: { incrementId: "" },
    //     });
    //     setShowModalCheckoutResult({ isShow: true, order: checkoutOrderData });
    // };

    useEffect(() => {
        getLocationApp();
    }, []);

    const onSearch = (filter: string) => {
        logSearch(filter);
        searchLogService.createSearchLog(filter);
        router.push(`/${params?.lng}/search?filter=${filter}`);
    };

    useEffect(() => {
        if (storeCarts?.length) {
            dispatch(updateFromSession(storeCarts));
        }
    }, []);

    return (
        <div className={`${s.marketplaceContainer}`}>
            <div>
                <section className={`${s.orderWrapper}`}>
                    <MarketHeader />
                    <div className={`search-container px-5 py-4`}>
                        <Input
                            placeholder={`${t("common.search_home_page")}...`}
                            className={`search-input h-[40px] px-5`}
                            autoFocus={true}
                            allowClear
                            onPressEnter={(e) => {
                                onSearch(e?.currentTarget?.value);
                            }}
                            prefix={
                                <img
                                    src="/icons/search.svg"
                                    alt="search"
                                    className="mr-1"
                                />
                            }
                        />
                    </div>
                    <StoreCartButton />
                    {isOpenStoreCart && <StoreCart />}
                    <PromotionSlider />
                    <div className={`${s.categorySliderContainer}`}>
                        <BusinessTypes />
                    </div>
                    <div
                        className={`${s.filterOptionContainer} divider pl-5 py-4`}
                    >
                        <StoreCategories />
                    </div>

                    <div className={`${s.shopSliderContainer} divider pl-5`}>
                        <SuggestionStores onViewDetail={onViewDetail} />
                    </div>

                    <div className="mt-4 px-5">
                        <NearlyStores
                            title={t("common.near_me")}
                            filter={filter}
                            onViewDetail={onViewDetail}
                        />
                    </div>
                    {/* {showModalCheckoutResult.isShow && (
                        <CheckoutResultDesktop
                            result={true}
                            setShowModalCheckoutResult={
                                setShowModalCheckoutResult
                            }
                            order={showModalCheckoutResult.order}
                        />
                    )} */}
                </section>
            </div>
        </div>
    );
};
